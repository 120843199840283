import React from "react"
import Link from 'gatsby-link'
import styled from 'styled-components'
import { rhythm } from "../utils/typography"

const List = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	list-style: none;
	padding: 0;
`

const NavLink = styled(Link)`
  box-shadow: none;
	background-image: none;
	margin-left: -1.5rem;

  &:hover {
    background-image: none;
  }
`

const Pagination = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <nav>
      <List style={{ marginTop: rhythm(1 / 4) }}>
        {!isFirst && (
          <NavLink to={`/blog/${prevPage}`} rel="prev">
            ← Previous Page
          </NavLink>
        )}
        {numPages > 1 && (
          Array.from({ length: numPages }, (_, i) => (
            <li
              key={`pagination-number${i + 1}`}
              style={{
                margin: 0,
              }}
            >
              <NavLink
                to={`/blog/${i === 0 ? '' : i + 1}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                {i + 1}
              </NavLink>
            </li>
          )))}
        {!isLast && (
          <NavLink to={`/blog/${nextPage}`} rel="next">
            Next Page →
          </NavLink>
        )}
      </List>
    </nav>
  )
}

export default Pagination
