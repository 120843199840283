import React from "react"
import { graphql } from "gatsby"

import Posts from '../components/posts'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Pagination from '../utils/pagination'

const BlogListTemplate = ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges
  const { currentPage, numPages } = pageContext
  return (
    <Layout link={location}>
      <Seo title="Blog List" />
      {posts.map(({ node }) => {
        return (
          <Posts vertex={node} key={node.id} />
        )
      })}
      <Pagination currentPage={currentPage} numPages={numPages} />
    </Layout>
  )
}

export default BlogListTemplate

export const pageQuery = graphql`
  query pageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`
