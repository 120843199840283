import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

const Article = styled.article`
  display: block;
  background-image: none;
  border-bottom: 1px solid #eee;
  width: 100%;
`

const PostLink = styled(Link)`
  box-shadow: none;
  background-image: none;

  &:hover {
    background-image: none;
  }
`

const Posts = ({ vertex }) => (
  <Article>
    <header>
      <PostLink to={vertex.fields.slug}>
        <h3 style={{ marginTop: `1rem` }}>
          {vertex.frontmatter.title || vertex.fields.slug}
        </h3>
      </PostLink>
      <small>{`${vertex.fields.readingTime.text}`}</small>
      <small style={{ float: "right" }}>
        {vertex.frontmatter.date}
      </small>
    </header>
    <section>
      <div style={{overflow: "hidden"}}>
      <p 
        style={{ marginBottom: `0.5rem`, float: "left" }}
        dangerouslySetInnerHTML={{
          __html: vertex.frontmatter.description || vertex.excerpt,
        }}
      />
    </div>
    </section>
  </Article>
)

export default Posts
